import App from './App.svelte';
import * as Sentry from '@sentry/svelte';
import { ENV } from './lib/config/environment';

if (ENV.sentryDSN) {
  Sentry.init({
    dsn: ENV.sentryDSN,
    // Performance Monitoring
    tracesSampleRate: 0.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0, // Capture 60% of sessions where an error happens

    integrations: [],
    normalizeDepth: 10,
  });
}

const app = new App({
  target: document.getElementById('root'),
  hydrate: import.meta.env.ROUTIFY_SSR_ENABLE,
});

export default app;
