export const browserNameAndVersion = (delimiter = ''): string => {
  const agent = navigator.userAgent.toLowerCase();
  const regStr_ie = /msie [\d.]+/gi;
  const regStr_ff = /firefox\/[\d.]+/gi;
  const regStr_chrome = /chrome\/[\d.]+/gi;
  const regStr_saf = /safari\/[\d.]+/gi;

  let browserNV: any;

  // ie
  if (agent.indexOf('msie') > 0) {
    browserNV = agent.match(regStr_ie);
  }

  // firefox
  if (agent.indexOf('firefox') > 0) {
    browserNV = agent.match(regStr_ff);
  }

  // chrome
  if (agent.indexOf('chrome') > 0) {
    browserNV = agent.match(regStr_chrome);
  }

  // safari
  if (agent.indexOf('safari') > 0 && agent.indexOf('chrome') < 0) {
    browserNV = agent.match(regStr_saf);
  }

  browserNV = browserNV.toString();

  // other
  if ('' === browserNV) {
    browserNV = 'Unknown';
  }

  if (
    browserNV.indexOf('firefox') !== -1 ||
    browserNV.indexOf('chrome') !== -1
  ) {
    browserNV = browserNV.replace('/', delimiter);
  }

  if (browserNV.indexOf('msie') !== -1) {
    browserNV = browserNV.replace('msie', 'ie').replace(/\s/g, delimiter);
  }

  return browserNV;
};
