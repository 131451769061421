import { derived, get } from 'svelte/store';
import { createProfileStore } from '../services/gql/user-profile.gql';
import { user } from './user';
import {
  type UserProfile,
  SubscriptionStatus,
} from '../services/gql/generated';

const defaultProfile: UserProfile = {
  id: '',
  isPremium: false,
  projectCount: 0,
  subscriptionStatus: SubscriptionStatus.ACTIVE,
  entitlements: {
    canCreateProjects: false,
    remainingAvatarExportDuration: 0,
    remainingExportDuration: 0,
  },
  acceptedTerms: null,
};

const _profileData = createProfileStore({
  pause: true,
});

user.subscribe((user) => {
  if (user) {
    if (get(_profileData.isPaused$)) _profileData.resume();
    else _profileData.refresh();
  }
});

const _profile = derived<typeof _profileData, UserProfile>(
  _profileData,
  ($profile) => {
    if ($profile.data && !$profile.error && !$profile.fetching) {
      return $profile.data.userProfile;
    }
    return defaultProfile;
  }
);

export const profile = {
  ..._profile,
  refresh: () => _profileData.refresh(),
};

export const hasPremiumAccess = derived(_profile, ($profile) => {
  if ($profile) {
    return (
      $profile.isPremium &&
      ($profile.subscriptionStatus === SubscriptionStatus.ACTIVE ||
        ($profile.subscriptionStatus === SubscriptionStatus.CANCELED &&
          $profile.renewsAt > Date.now()))
    );
  }

  return false;
});
