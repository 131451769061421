<script context="module">
  import { Router, createRouter } from '@roxi/routify';
  import CustomToast from './lib/components/atoms/CustomToast.svelte';
  import routes from '../.routify/routes.default.js';
  import './styles/fonts.css';
  import 'flexboxgrid/css/flexboxgrid.min.css';
  import './styles/global.css';
  import { AnalyticsService } from './lib/services/analytics.service';
  import * as Sentry from '@sentry/svelte';

  const router = createRouter({ routes });
</script>

<!-- svelte-ignore missing-declaration -->
<script lang="ts">
  import { onMount } from 'svelte';
  import firebaseStore from './lib/stores/firebaseStore';
  import { setContext } from 'svelte';
  import { speechifyMobileUser, user } from './lib/stores/user';
  import { profile } from './lib/stores/profile';

  setContext('user', $user);
  setContext('profile', $profile);
  setContext('analytics', AnalyticsService);
  onMount(() => {
    firebaseStore.init();
  });
  $: {
    if ($speechifyMobileUser) {
      Sentry.setUser({
        id: $speechifyMobileUser.uid,
      });
    }
  }
</script>

<Router {router} />
<CustomToast />
