<script lang="ts">
  export let className: string = '';
  export let fillClass: string = 'default-fill-class';
</script>

<svg
  class={className}
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="functional icon">
    <path
      id="&#244;&#128;&#129;&#158;"
      d="M12 22.0097C17.4739 22.0097 22 17.4836 22 12.0097C22 6.52611 17.4642 2 11.9903 2C6.51644 2 2 6.52611 2 12.0097C2 17.4836 6.52611 22.0097 12 22.0097ZM12 20.0077C7.56093 20.0077 4.00193 16.4391 4.00193 12.0097C4.00193 7.5706 7.55126 4.00193 11.9903 4.00193C16.4294 4.00193 19.9981 7.5706 20.0077 12.0097C20.0077 16.4391 16.4391 20.0077 12 20.0077ZM11.9903 13.6344C12.5222 13.6344 12.8317 13.3346 12.8414 12.7737L12.9961 8.12186C13.0058 7.55126 12.5803 7.14507 11.9807 7.14507C11.381 7.14507 10.9749 7.54159 10.9845 8.11219L11.1199 12.7834C11.1393 13.3346 11.4487 13.6344 11.9903 13.6344ZM11.9903 16.8453C12.619 16.8453 13.1315 16.3907 13.1315 15.7814C13.1315 15.1625 12.6286 14.7176 11.9903 14.7176C11.3617 14.7176 10.8491 15.1721 10.8491 15.7814C10.8491 16.3907 11.3714 16.8453 11.9903 16.8453Z"
      class={fillClass}
    />
  </g>
</svg>

<style>
  .default-fill-class {
    fill: #364a63;
  }
</style>
