import { getCurrentUser } from '../../stores/user';

export async function getAuthHeaders(refresh = false) {
  const user = await getCurrentUser();
  if (!user) return {};
  const idToken = await user.getIdToken(refresh);
  return {
    Authorization: `Bearer ${idToken}`,
  };
}
