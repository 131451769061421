

export default {
  "meta": {},
  "id": "_default",
  "_regex": {},
  "_paramKeys": {},
  "file": {
    "path": "src/routes/_module.svelte",
    "dir": "src/routes",
    "base": "_module.svelte",
    "ext": ".svelte",
    "name": "_module"
  },
  "asyncModule": () => import('../src/routes/_module.svelte'),
  "rootName": "default",
  "routifyDir": import.meta.url,
  "children": [
    {
      "meta": {
        "dynamic": true
      },
      "id": "_default__projectId_",
      "_regex": {},
      "_paramKeys": {},
      "name": "[projectId]",
      "module": false,
      "file": {
        "path": "src/routes/[projectId]",
        "dir": "src/routes",
        "base": "[projectId]",
        "ext": "",
        "name": "[projectId]"
      },
      "children": [
        {
          "meta": {},
          "id": "_default__projectId__studio",
          "_regex": {},
          "_paramKeys": {},
          "name": "studio",
          "file": {
            "path": "src/routes/[projectId]/studio/_module.svelte",
            "dir": "src/routes/[projectId]/studio",
            "base": "_module.svelte",
            "ext": ".svelte",
            "name": "_module"
          },
          "asyncModule": () => import('../src/routes/[projectId]/studio/_module.svelte'),
          "children": [
            {
              "meta": {},
              "id": "_default__projectId__studio_search_svelte",
              "_regex": {},
              "_paramKeys": {},
              "name": "search",
              "file": {
                "path": "src/routes/[projectId]/studio/search.svelte",
                "dir": "src/routes/[projectId]/studio",
                "base": "search.svelte",
                "ext": ".svelte",
                "name": "search"
              },
              "asyncModule": () => import('../src/routes/[projectId]/studio/search.svelte'),
              "children": []
            },
            {
              "meta": {},
              "id": "_default__projectId__studio_translate_svelte",
              "_regex": {},
              "_paramKeys": {},
              "name": "translate",
              "file": {
                "path": "src/routes/[projectId]/studio/translate.svelte",
                "dir": "src/routes/[projectId]/studio",
                "base": "translate.svelte",
                "ext": ".svelte",
                "name": "translate"
              },
              "asyncModule": () => import('../src/routes/[projectId]/studio/translate.svelte'),
              "children": []
            }
          ]
        }
      ]
    },
    {
      "meta": {},
      "id": "_default_create_svelte",
      "_regex": {},
      "_paramKeys": {},
      "name": "create",
      "file": {
        "path": "src/routes/create.svelte",
        "dir": "src/routes",
        "base": "create.svelte",
        "ext": ".svelte",
        "name": "create"
      },
      "asyncModule": () => import('../src/routes/create.svelte'),
      "children": []
    },
    {
      "meta": {},
      "id": "_default_index_svelte",
      "_regex": {},
      "_paramKeys": {},
      "name": "index",
      "file": {
        "path": "src/routes/index.svelte",
        "dir": "src/routes",
        "base": "index.svelte",
        "ext": ".svelte",
        "name": "index"
      },
      "asyncModule": () => import('../src/routes/index.svelte'),
      "children": []
    },
    {
      "meta": {},
      "id": "_default_public",
      "_regex": {},
      "_paramKeys": {},
      "name": "public",
      "module": false,
      "file": {
        "path": "src/routes/public",
        "dir": "src/routes",
        "base": "public",
        "ext": "",
        "name": "public"
      },
      "children": [
        {
          "meta": {
            "dynamic": true
          },
          "id": "_default_public__jobId_",
          "_regex": {},
          "_paramKeys": {},
          "name": "[jobId]",
          "module": false,
          "file": {
            "path": "src/routes/public/[jobId]",
            "dir": "src/routes/public",
            "base": "[jobId]",
            "ext": "",
            "name": "[jobId]"
          },
          "children": [
            {
              "meta": {},
              "id": "_default_public__jobId__preview_svelte",
              "_regex": {},
              "_paramKeys": {},
              "name": "preview",
              "file": {
                "path": "src/routes/public/[jobId]/preview.svelte",
                "dir": "src/routes/public/[jobId]",
                "base": "preview.svelte",
                "ext": ".svelte",
                "name": "preview"
              },
              "asyncModule": () => import('../src/routes/public/[jobId]/preview.svelte'),
              "children": []
            }
          ]
        }
      ]
    },
    {
      "meta": {},
      "id": "_default_share",
      "_regex": {},
      "_paramKeys": {},
      "name": "share",
      "module": false,
      "file": {
        "path": "src/routes/share",
        "dir": "src/routes",
        "base": "share",
        "ext": "",
        "name": "share"
      },
      "children": [
        {
          "meta": {
            "dynamic": true
          },
          "id": "_default_share__token_",
          "_regex": {},
          "_paramKeys": {},
          "name": "[token]",
          "module": false,
          "file": {
            "path": "src/routes/share/[token]",
            "dir": "src/routes/share",
            "base": "[token]",
            "ext": "",
            "name": "[token]"
          },
          "children": [
            {
              "meta": {},
              "id": "_default_share__token__access",
              "_regex": {},
              "_paramKeys": {},
              "name": "access",
              "module": false,
              "file": {
                "path": "src/routes/share/[token]/access",
                "dir": "src/routes/share/[token]",
                "base": "access",
                "ext": "",
                "name": "access"
              },
              "children": [
                {
                  "meta": {},
                  "id": "_default_share__token__access_index_svelte",
                  "_regex": {},
                  "_paramKeys": {},
                  "name": "index",
                  "file": {
                    "path": "src/routes/share/[token]/access/index.svelte",
                    "dir": "src/routes/share/[token]/access",
                    "base": "index.svelte",
                    "ext": ".svelte",
                    "name": "index"
                  },
                  "asyncModule": () => import('../src/routes/share/[token]/access/index.svelte'),
                  "children": []
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "meta": {
        "dynamic": true,
        "dynamicSpread": true
      },
      "_regex": {},
      "_paramKeys": {},
      "name": "[...404]",
      "file": {
        "path": ".routify/components/[...404].svelte",
        "dir": ".routify/components",
        "base": "[...404].svelte",
        "ext": ".svelte",
        "name": "[...404]"
      },
      "asyncModule": () => import('./components/[...404].svelte'),
      "children": []
    }
  ]
}