<script lang="ts">
  export let className: string = '';
</script>

<svg
  class={className}
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="functional icon">
    <path
      id="&#244;&#128;&#133;&#190;"
      d="M2.82194 11.9313C2.61882 12.1344 2.61101 12.4782 2.82194 12.6813C3.02507 12.8923 3.36882 12.8844 3.57194 12.6813L7.75163 8.50163L11.9313 12.6813C12.1344 12.8844 12.4782 12.8923 12.6813 12.6813C12.8923 12.4782 12.8844 12.1344 12.6813 11.9313L8.50163 7.75163L12.6813 3.56413C12.8844 3.36101 12.8923 3.02507 12.6813 2.82194C12.4782 2.61101 12.1344 2.61882 11.9313 2.82194L7.75163 7.00163L3.57194 2.82194C3.36882 2.61882 3.02507 2.61101 2.82194 2.82194C2.61101 3.02507 2.61882 3.36101 2.82194 3.56413L7.00163 7.75163L2.82194 11.9313Z"
      fill="#778FAD"
    />
  </g>
</svg>
